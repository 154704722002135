<template>
  <div>
    <div class="tabbar">
      <div class="w">
        <div class="logo" @click="goHome">
          <img v-lazy="logoInfo" alt="logo"/>
        </div>
        <div class="nav">
          <!-- <ul v-if="isLoginLocal ? (isLoginLocal == 0 ? true : false) : ($store.state.loginStatus == 0 ? true : false)"> -->
          <ul v-if="ifLogin()">
            <li v-for="(item,index) in navItem" :key="item.id" @mouseover="ItemMouseover(index)">
              <router-link :to="item.src" exact-active-class="active">{{item.text}}</router-link>
            </li>
          </ul>
          <ul v-else class="nav-login">
            <li v-for="(item,index) in navItems" :key="item.id" @mouseover="ItemMouseover(index)">
              <router-link :to="item.src" exact-active-class="active">{{item.text}}</router-link>
            </li>
            <div class="avatar-box">
              <div class="avatar">
                <img src="../assets/images/login/default_avatar.png" alt="">
                <h2 class="registerForm">{{username()}}</h2>
              </div>
              <div class="remove-login">
                <p @click="removeLogin">退出</p>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
    <home-select v-show="$store.state.isShow"/>
  </div>
</template>

<script>
import HomeSelect from "../views/HomeChildren/HomeSelect.vue";
import { Dialog } from 'vant';

export default {
  name: "NavTabbar",
  data() {
    return {
      logoInfo: require("../assets/images/logo.png"),
      navItem: [
        {
          text: "首页",
          src: "/",
        },
        {
          text: "小程序开发",
          src: "/routine",
        },
        {
          text: "产品及服务",
          src: "/products",
        },
        {
          text: '解决方案',
          src: '/solution'
        },
        {
          text: "开发流程",
          src: "/process",
        },
        {
          text: "动态资讯",
          src: "/information",
        },
        {
          text: "关于我们",
          src: "/about",
        },
        // {
        //   text: "加入我们",
        //   src: "/join",
        // },
        {
          text: "联系我们",
          src: "/contact",
        },
        {
          text: "登录",
          src: "/login",
        },
        {
          text: "注册",
          src: "/register",
        }
      ],
      navItems: [
        {
          text: "首页",
          src: "/",
        },
        {
          text: "小程序开发",
          src: "/routine",
        },
        {
          text: "产品及服务",
          src: "/products",
        },
        {
          text: '解决方案',
          src: '/solution'
        },
        {
          text: "开发流程",
          src: "/process",
        },
        {
          text: "动态资讯",
          src: "/information",
        },
        {
          text: "关于我们",
          src: "/about",
        },
        {
          text: "联系我们",
          src: "/contact",
        }
      ]
    };
  },
  methods: {
    goHome() {
      this.$router.push({ path: "/"});
    },
    ItemMouseover(index) {
      if(index == 2) {
        this.$store.state.isShow = true
      }else {
        this.$store.state.isShow = false
      }
    },
    removeLogin() {
      Dialog.confirm({
        title: '提示',
        message: '您确定要退出登录吗？',
      }).then(() => {
        // console.log('onconfirm');
        // localStorage.setItem('loginStatus',0)
        localStorage.removeItem('loginStatus')
        this.$store.state.loginStatus = 0
        location.reload()
      }).catch(() => {
        // console.log('oncancel');
      });
      // console.log(this.isLogin);
    },
    ifLogin() { 
      if(localStorage.getItem('loginStatus')) {
        if(localStorage.getItem('loginStatus') == 1) {
          return false
        }
      }else {
        if(this.$store.state.loginStatus == 0) {
          return true
        }else {
          return false
        }
      }
    },
    username() {
      if(localStorage.getItem('username')) {
        return localStorage.getItem('username')
      }else {
        return this.$store.state.username
      }
    }
  },
  components: {
    HomeSelect,
  }
};
</script>

<style lang="less" scoped>
.nav .active {
  font-family: "PingFangSC-Medium, PingFang SC";
  color: rgba(0, 0, 0, 0.8);
  font-weight: 600;
  border-bottom: .1875rem solid #0662fb;
}
.tabbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 5rem;
  background: transparent;
  z-index: 99;
  .w {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 85%;
    margin: 0 auto;
    padding-top: 1.875rem;
  }
}
.logo {
  height: 3.5rem;
  margin-top: -0.5rem;
  cursor: pointer;
  img {
    width: 11.4375rem;
  }
}
.nav {
  width: 70%;
  ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    li {
      font-size: 1rem;
      font-family: "PingFangSC-Regular, PingFang SC";
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
      list-style: none;
      a {
        padding-bottom: 1.75rem;
      }
      @media screen and (max-width: 1366px) {
        a {
          padding-bottom: 1.5rem;
        }
      }
    }
  }
  .nav-login {
    padding-right: 5rem;
  }
}
.avatar-box {
  position: absolute;
  right: 1.5rem;
  top: 1.5rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  .avatar {
    display: flex;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      vertical-align: middle;
    }
    .registerForm {
      width: 3.75rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1rem;
      color: #7261ff;
      margin-left: .375rem;
    }
  }
  .remove-login {
    margin-left: 1rem;
    p {
      color: rgba(0, 0, 0, 0.6);
      font-size: 1rem;
      cursor: pointer;
    }
    p:hover {
      color: #7261ff;
    }
  }
}
</style>