import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css'
import { Popup } from 'vant'
import 'vant/lib/index.css';
import lazyLoad from 'vue-lazyload'
require('./assets/less/pcMedia.less')

router.beforeEach((to, from, next) => {
  // console.log(store.state.navbarShow);
  if(to.path === '/login') {
    store.state.navbarShow = false
    next()
  }else if(to.path === '/register') {
    store.state.navbarShow = false
    next()
  }else {
    store.state.navbarShow = true
    next()
  }
})

Vue.use(Popup)
Vue.use(lazyLoad)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
