import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
  },
  // {
  //   name: 'home', 
  //   path: '/home',
  //   component: () => import('../views/Home.vue'),
  // },
  {
    path: '/routine',
    component: () => import('../views/Routine.vue')
  },
  {
    path: '/products',
    component: () => import('../views/Products.vue')
  },
  {
    path: '/process',
    component: () => import('../views/Process.vue')
  },
  {
    path: '/information',
    component: () => import('../views/Information.vue')
  },
  {
    path: '/informationDetail',
    component: () => import('../views/informationDetail.vue')
  },
  {
    path: '/about',
    component: () => import('../views/About.vue')
  },
  {
    path: '/solution',
    component: () => import('../views/Solution.vue')
  },
  {
    path: '/solutionDetail',
    component: () => import('../views/solutionDetail.vue')
  },
  {
    path: '/contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/website',
    component: () => import('../views/Website.vue'),
  },
  {
    path: '/h5',
    component: () => import('../views/H5.vue'),
  },
  {
    path: '/official',
    component: () => import('../views/Official.vue'),
  },
  {
    path: '/login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/register',
    component: () => import('../views/register.vue')
  }
]


const createRouter = () =>
  new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    mode: "history",
    // mode: "hash",
    routes: routes,
  });
 
const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

//解决路由重复点击报错问题
// 保存原型对象的push
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
// 重写push方法
VueRouter.prototype.push = function(location,res,rej) {
  if(res&&rej) {
    originPush.call(this,location,res,rej)
  }else {
    originPush.call(this,location,() => {},() => {})
  }
}
// 重写replace方法
VueRouter.prototype.replace = function(location,res,rej) {
  if(res&&rej) {
    originReplace.call(this,location,res,rej)
  }else {
    originReplace.call(this,location,() => {},() => {})
  }
}

export default router
