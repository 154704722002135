<template>
  <div class="nav-select" :class="{imgActive: imgSrc}" @mouseout="SelectMouseout" v-show="$store.state.isShow" @mouseover="SelectMouseover">
    <div class="w">
      <ul>
        <li @click="goRoutine">小程序开发</li>
        <li @click="goWebsite">网站建设</li>
        <li @click="goOfficial">公众号开发</li>
        <li @click="goH5">H5开发</li>
      </ul>
      <ul class="ul2">
        <li @click="sulotionDetail(11)">
            <h3>兴趣电商解决方案</h3>
            <p>建立私有化流量池</p>
        </li>
        <li @click="sulotionDetail(1)">
            <h3>家居行业解决方案</h3>
            <p>家居智慧新零售</p>
        </li>
        <li @click="sulotionDetail(7)">
            <h3>教育行业解决方案</h3>
            <p>实现业务转型升级</p>
        </li>
        <li @click="sulotionDetail(8)">
            <h3>家政行业解决方案</h3>
            <p>为您节约成本 助力发展</p>
        </li>
        <li @click="sulotionDetail(9)">
            <h3>装修行业解决方案</h3>
            <p>业主、工人、装企、供应商四大闭环</p>
        </li>
        <li @click="sulotionDetail(4)">
            <h3>移动办公解决方案</h3>
            <p>数字化转型 降本增效</p>
        </li>
      </ul>
      <div class="select-ma">
        <img src="../../assets/images/customer-ma.jpg" alt="">
        <p>智慧互联网解决方案</p>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store/index'

export default {
  name: "HomeSelect",
  data() {
    return{
      // iShow: false,
      imgSrc: false,
      id: null
    }
  },
  methods: {
    SelectMouseover() {
      this.id = this.$route.params.id
        console.log(this.$route.push);
      if(this.$route.params.id == 1) {
        console.log(this.$route.params.id);
        this.imgSrc = true
      }else {
        // console.log(this.$route.params.id);
        this.imgSrc = false
      }
      store.state.isShow = true
      
    },
    SelectMouseout() {
      store.state.isShow = false
    },
    goWebsite() {
      this.$router.push('/website')
    },
    goH5() {
      this.$router.push('/h5')
    },
    goOfficial() {
      this.$router.push('/official')
    },
    goRoutine() {
      this.$router.push('/routine')
    },
    sulotionDetail(id) {
      this.$router.push(`/solutionDetail?id=${id}`)
    }
  },
  store
}
</script>

<style lang="less" scoped>
  .imgActive {
    background: url('../../assets/images/home-select-bnr.png') no-repeat;
    background-size: cover;
  }
  .nav-select {
    position: absolute;
    top: 4.9375rem;
    left: 0;
    background-color: #FFF;
    width: 100%;
    height: 17.5rem;
    padding-top: 1.875rem;
    // background: linear-gradient(137deg, #f3f7ff 0%,#ebebfd 100%);
    z-index: 9;
    // border: 1px solid red;
   .w {
    width: 80%;
    margin: 0 auto;
     ul {
      float: left;
      width: 9.375rem;
      margin-left: 21.25rem;
      padding-top: .75rem;
      border-right: .0625rem dashed rgba(0,0,0,0.2);
      li {
        width: 6.25rem;
        margin: 2rem 0;
        font-size: .875rem;
        font-family: 'PingFangSC-Regular, PingFang SC';
        font-weight: 400;
        color: rgba(0,0,0,0.6);
        transition: all 0.2s;
        cursor: pointer;
        &:first-child {
          margin-top: 0;
        }
      }
    }
    ul:nth-child(1) li:hover {
      transform: translateX(.375rem);
      font-weight: 600;
      color: #0063FB;
      transition: all 0.2s;
    }
    .ul2 {
      display: flex;
      flex-wrap: wrap;
      width: 25rem;
      margin-left: 3.125rem;
      padding-top: .75rem;
      border-right: 0;
      li {
        width: 10.5rem;
        list-style: none;
        margin: 0 0 1.5625rem 0;
        cursor: pointer;
        &:nth-child(2n) {
          margin-left: 2.5rem;
        }
        h3 {
          font-size: .875rem;
          font-family: 'PingFangSC-Regular, PingFang SC';
          font-weight: 400;
          color: rgba(0,0,0,0.8);
          transition: all .2s;
        }
        p {
          font-size: .75rem;
          font-family: 'PingFangSC-Regular, PingFang SC';
          font-weight: 400;
          color: rgba(0,0,0,0.4);
          margin-top: .375rem;
          transition: all .2s;
        }
        &:hover h3 {
          color: #0063FB;
          font-weight: 600;
          transform: translateX(.375rem);
          transition: all 0.2s;
        }
        &:hover p {
          color: #0063FB;
          opacity: 0.8;
          transform: translateX(.375rem);
          transition: all 0.2s;
        }
      }
      @media screen and (max-width: 1280px) {
        li {
          margin-bottom: 1.875rem;
        }
      }
    }
    @media screen and (max-width: 1366px) {
      .ul2 {
        width: 31.25rem;
        margin-top: -.875rem;
        li {
          margin-bottom: .875rem;
          p {
            width: 12.5rem;
          }
        }
      }
    }
    @media screen and (max-width: 1288px) {
      .ul2 {
        width: 31.25rem;
        li {
          margin-bottom: 1.25rem;
          p {
            width: 12.5rem;
          }
        }
      }
    }
    .select-ma {
      float: right;
      width: 9.375rem;
      text-align: center;
      padding-top: 1.25rem;
      img {
        width: 5rem;
        cursor: pointer;
      }
      p {
        font-size: .75rem;
        font-family: 'PingFangSC-Regular, PingFang SC';
        font-weight: 400;
        color: rgba(0,0,0,0.8);
        margin-top: .625rem;
      }
    }
   }
  }
</style>