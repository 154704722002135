import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  isShow: false,
  customerHeadId: 0,
  customerId: 0,
  navbarShow: true,
  loginStatus: 0,
  username: ''
}

const getters = {}

const mutations = {}

const actions = {}

const modules = {}

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules,
})
