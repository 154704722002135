<template>
  <div id="app">
    <router-view :key="key"/>
    <nav-tabbar v-show="$store.state.navbarShow"></nav-tabbar>
  </div>
</template>

<script>
// 引入pc端的导航栏
import NavTabbar from "./components/NavTabbar.vue"


export default {
  name: "App",
  data() {
    return {}
  },
  components: {
    NavTabbar
  },
  computed: {
    key() {
      return this.$route.path + Math.random();
    }
  }
}
</script>
<style lang="less">
  @import "assets/css/base.css";
  html {
    overflow-x: hidden;
  }
</style>
